.allens-sass-class {
  color: red;
  .child {
    color: green;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
